@import '../../styles/gifts/sass/basics';

.add-to-basket-for-teasers {
  :global {
    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__plus-icon {
    width: 16px;
  }
}
