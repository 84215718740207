@import '../../../styles/gifts/sass/basics';
@value gift-item, gift-item__content, gift-item__description from '../../GiftItem/index.module.scss';

.bb-popular-gifts-row {
  margin: 64px auto;
  background-color: $white;

  &__title {
    font-size: 24px;
    line-height: $line-height-base;
    color: $turquoise-1200;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;

    a {
      border-bottom: 2px solid $turquoise-1000;
      color: $turquoise-1000;
      display: none;
      text-decoration: none;

      svg {
        margin-left: 4px;
      }
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 64px;
  }

  .gift-item__description {
    display: none;
  }

  &__footer {
    margin-top: 64px;
    display: block;
    text-align: right;

    a {
      border-bottom: 2px solid $turquoise-1000;
      color: $turquoise-1000;
      text-decoration: none;
      font-size: 18px;

      svg {
        margin-left: 4px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin: 80px auto;

    &__title {
      text-align: left;
      width: auto;
    }

    &__heading {
      a {
        display: inline-block;
      }
    }

    &__items {
      flex-direction: row;
    }

    .gift-item {
      width: calc(33.33% - 24px);
    }

    &__footer {
      display: none;
    }
  }

 @include media-breakpoint-between(md, lg) {
   .gift-item {
     display: none;

     &:nth-child(1),
     &:nth-child(2),
     &:nth-child(3) {
       display: flex;
     }
   }
 }

  @include media-breakpoint-up(lg) {
    &__title {
      font-size: 28px;
      line-height: 1.4;
    }

    &__4-items-per-row {
      .gift-item {
        width: calc(25% - 18px);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__title {
      font-size: 36px;
    }
  }
}
