@import '../../../styles/gifts/sass/basics';
@value gift-item, gift-item__content, gift-item--default, gift-item__description from '../../GiftItem/index.module.scss';

.bb-gifts-grid {
  //max-width: 1440px;
  margin: 36px auto;

  background-color: $white;
  &__title {
    font-size: 24px;
    line-height: $line-height-base;
    padding-bottom: 36px;
    color: $green-800;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    padding-top: 8px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    column-gap: 24px;
    row-gap: 64px;

    &::after {
      content: "";
      flex: auto;
    }
  }

  .gift-item__description {
    display: none;
  }

  .gift-item--default .gift-item__content {
    text-align: center;
  }

  @media screen and (min-width: 360px) {
    .gift-item {
      width: calc(50% - 12px);
    }
  }

  @include media-breakpoint-up(md) {
    .gift-item {
      width: calc(33.33% - 16px);
    }
  }

  @include media-breakpoint-up(lg) {
    &__title {
      font-size: 30px;
      line-height: 1.4;
      padding-bottom: 44px;
    }

    &__4-items-per-row {
      .gift-item {
        width: calc(25% - 18px);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &__title {
      font-size: 36px;
      padding-top: 0;
    }
  }
}
