@import '../../styles/gifts/sass/basics';
@import '../../styles/mixins/default-picture';

.gift-item {
  width: 100%;

  &--gift_santa_letter {
    .gift-item__link {
      .gift-item__details {
        .gift-item__title {
          color: $red-1200;
        }
      }
    }
  }

  // Default variant.
  &--default {
    .gift-item {
      &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        height: 100%;

        &:focus-within .gift-item__link,
        &:hover .gift-item__link {
          background-color: $green-50;
        }
      }

      &__new {
        position: absolute;
        top: -28px;
        left: 0;
        right: 0;
        z-index: 1;
        justify-self: center;

        &-content {
          position: relative;
          font-size: 12px;
          color: $white;
          padding: 12px 16px;
          background-color: $turquoise-1200;
          border-radius: 4px;
          margin: 0 auto;
          display: inline-block;
          line-height: $line-height-large;

          &:after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 9px 9px 9px;
            border-color: transparent transparent $turquoise-1200 transparent;
            transform: rotate(180deg);
          }
        }
      }

      &__image {
        position: relative;

        :global {
          @include default-picture();
        }

        img {
          transition: opacity 0.35s;
          max-height: 250px;
          object-fit: contain;
          aspect-ratio: 36/25;
        }
      }

      &__link {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: $gray-1000;
        padding-bottom: 56px;
        border-radius: 4px;
        transition: background-color 0.3s;
        gap: 10px;

        &:focus-visible {
          box-shadow: 0 0 0 2px $blue-400;
        }

        &:focus,
        &:hover {
          .gift-item__image:after {
            opacity: 1;

            @media #{$mobile-land} {
              opacity: 0;
            }
          }
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0;
      }

      &__title {
        font-size: 18px;
        color: $green-800;
        font-weight: bold;
        margin: 10px 0 4px;
      }

      &__description {
        font-size: $font-size-base;
        margin-top: 6px;
        color: $gray-1000;
      }

      &__price {
        font-size: 18px;
        color: $slate-800;
      }

      &__button {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 0;
        padding-right: 0;
        transition: background-color, color 0.3s;
      }
    }

    @include media-breakpoint-up(lg) {
      .gift-item {
        &__new {
          top: -20px;
        }

        &__link {
          padding: 8px 8px 60px;
          margin: 0 -8px;
          position: relative;
        }

        &__button {
          padding-right: 16px;
          bottom: 8px;
        }

        &__price,
        &__title {
          font-size: 22px;
        }

        &__image {
          &:after {
            opacity: 0;
            position: absolute;
            content: 'View gift';
            background-color: $green-800;
            border-radius: 25px;
            width: 120px;
            height: 44px;
            text-align: center;
            line-height: 44px;
            color: $white;
            font-weight: bold;
            font-size: $font-size-base;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            transition: opacity 0.3s;
          }
        }
      }
    }
  }

  // Modern variant.
  &--modern {
    .gift-item {
      &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 0 0;
        height: 100%;

        &:focus-within img,
        &:hover img {
          opacity: 60%;
        }
      }

      &__new {
        position: absolute;
        top: -28px;
        left: 0;
        right: 0;
        z-index: 1;
        justify-self: center;

        &-content {
          position: relative;
          font-size: 12px;
          color: $white;
          padding: 12px 16px;
          background-color: $turquoise-1200;
          border-radius: 4px;
          margin: 0 auto;
          display: inline-block;
          line-height: $line-height-large;
          &:after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 9px 9px 9px;
            border-color: transparent transparent $turquoise-1200 transparent;
            transform: rotate(180deg);
          }
        }
      }

      &__image {
        position: relative;
        :global {
          @include default-picture();
        }
        img {
          transition: opacity 0.35s;
          max-height: 250px;
          object-fit: contain;
          aspect-ratio: 36/25;
          border-radius: 4px;
        }
      }

      &__link {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        color: $gray-1000;
        padding-bottom: 56px;
        border-radius: 4px;
        transition: background-color 0.3s;
        gap: 10px;

        &:focus-visible {
          box-shadow: 0 0 0 2px $blue-400;
        }
      }

      &__details {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0;
        line-height: $line-height-normal;
      }

      &__title {
        font-size: 18px;
        color: $green-800;
        font-weight: bold;
        margin-bottom: 4px;
      }

      &__description {
        font-size: $font-size-base;
        margin-top: 6px;
        color: $slate-1200;
      }

      &__price {
        font-size: 18px;
        color: $slate-1200;
        margin-bottom: 4px;
      }

      &__button {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 0;
        padding-right: 0;
        transition: background-color, color 0.3s;
      }

      &__ecard {
        color: $pink-1200;
        font-size: 12px;
        margin-top: 8px;
        font-weight: 600;
      }

      &__highlighted {
        display: flex;
        gap: 4px;
        flex-flow: wrap;

        span {
          color: $white;
          padding: 4px 8px;
          border-radius: 100px;
          font-size: 12px;
          margin-top: 8px;
          font-weight: 600;
          width: fit-content;
        }

        @include highlighed-category-backgrounds();
      }
    }

    @include media-breakpoint-up(lg) {
      .gift-item {
        &__link {
          padding-bottom: 60px;
        }

        &__title {
          font-size: 22px;
        }
      }
    }
  }
}
