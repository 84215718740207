@import '../../../../styles/gifts/sass/basics';
@value gift-item, gift-item__description from '../../../GiftItem/index.module.scss';

.bb-catalog-with-filters {
  background-color: $white;
  margin: -28px auto 64px;
  padding-top: 0;

  &__gifts-grid {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 24px;
    row-gap: 64px;

    &::after {
      content: "";
      flex: auto;
    }
  }

  .gift-item__description {
    display: none;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding-top: 16px;

    @include media-breakpoint-down(xl) {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 6px;
      padding-top: 0;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__title {
    color: $turquoise-1000;
    font-size: 28px;
    margin-top: 44px;

    @include media-breakpoint-up(lg) {
      font-size: 36px;
      line-height: 36px;
      margin-top: 56px;
    }
  }

  &:before {
    background: $gray-100;
    content: '';
    display: block;
    height: 44px;
    top: 41px;
    position: relative;
    width: 100%;
    z-index: 1;

    @include media-breakpoint-up(xl) {
      height: 54px;
      top: 64px;
    }
  }

  @media screen and (min-width: 360px) {
    .gift-item {
      width: calc(50% - 12px);
    }
  }

  @include media-breakpoint-up(md) {
    padding-top: 0;

    .gift-item {
      width: calc(33.33% - 16px);
    }
  }

  @include media-breakpoint-up(lg) {
    margin: -34px auto 64px;

    .gift-item {
      width: calc(25% - 18px);
    }
  }

  :global(.container) {
    position: relative;
    z-index: 2;
  }
}