@import '../../styles/gifts/sass/basics';

.catalog-order-by {
  display: flex;
  gap: 8px;

  &__select {
    :global(.select__options) {
      @include media-breakpoint-down(lg) {
        left: -45px
      }
    }
  }

  &__label {
    color: $slate-1200;
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
