@import '../../../styles/gifts/sass/basics';

.category-filter {
  display: table;
  width: 100%;
  margin-top: 6px;
  margin-bottom: floor($line-height-computed * 1.2);

  &__item {
    &--highlighted {
      @include highlighed-category-links();
    }
  }

  &__button {
    display: inline-block;
    font-size: $nav-filters-font-size;
    font-weight: $font-weight-bold;
    color: $pink-1200;
    background-color: transparent;
    border: none;
    padding: 0;
    border-bottom: solid $pink-1200 3px;
    padding-bottom: 4px;
    text-decoration: none;
    text-transform: uppercase;
    @media (min-width: $screen-lg) {
      display: none;
    }
    &--collapsed {
      border-bottom-color: transparent;
      .category-filter__icon {
        color: $pink-1200;
        transform: rotate(90deg);
      }
    }
    &:active,
    &:hover,
    &:visited,
    &:focus {
      color: $pink-1200;
      outline: none;
    }
    .category-filter__icon {
      color: $biscuit-1000;
      font-size: 24px;
      transition: all 0.1s linear;
    }
  }

  :global {
    .nav-filters {
      max-width: 1200px !important;
    }
  }

  @media #{$tablet-land} {
    :global {
      .nav-filters > li {
        margin-left: 11px;
        margin-right: 11px;
      }
    }
  }
}
