@import '../../../styles/gifts/sass/basics';
@value gift-item--default, gift-item__description from '../../GiftItem/index.module.scss';

.bb-products-carousel {
  @include bb-standalone-spacing-new();
  padding: 8px 0;
  overflow: hidden;

  .gift-item--default {
    text-align: center;
  }

  .gift-item__description {
    display: none;
  }
  
  :global {
    .slick-slide {
      width: 179px;
      height: auto;
      display: flex !important;

      & > div {
        display: flex;
        height: 100%;
        width: 100%;
      }
    }

    .slick-track {
      display: flex;
    }

    .slick-list {
      overflow: visible;
      margin-left: -8px;
    }
  }

  &__heading-group {
    margin-bottom: 24px;
  }

  &__heading {
    font-size: 28px;
    color: $green-1000;
    margin-bottom: 0;
    font-weight: $font-weight-bold;
  }

  &__arrows {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-bottom: 24px;
  }

  &__arrow {
    display: block;
    width: 40px;
    height: 40px;
    padding: 7px;
    border-radius: 50%;
    border: 1px solid transparent;
    appearance: none;
    background: $slate-200;
    cursor: pointer;

    &:not(&--disabled):hover {
      background: $slate-400;
      border-color: $slate-200;
    }

    &--prev svg {
      transform: rotate(90deg);
    }

    &--next svg {
      transform: rotate(-90deg);
    }

    svg {
      fill: $slate-1200;
    }

    &--disabled {
      svg {
        fill: $gray-400;
      }
    }
  }

  &__slide {
    width: 100%;
    padding: 0 8px;
  }

  &__actions-mob {
    margin-top: 64px;
    display: flex;

    button {
      flex-grow: 1;
      display: block;
    }
  }

  @include media-breakpoint-up(sm) {
    &__actions-mob {
      justify-content: center;

      button {
        flex-grow: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    :global {
      .slick-slide {
        width: 338px;
      }

      .slick-list {
        margin-left: -12px;
      }
    }

    &__slide {
      padding: 0 12px;
    }
  }

  @include media-breakpoint-up(lg) {
    :global {
      .slick-slide {
        width: 434px;
      }
    }

    &__heading-group {
      display: flex;
      gap: 16px 48px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 56px;
    }

    &__heading {
      max-width: 410px;
      font-size: 36px;
    }
  }
}
