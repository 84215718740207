@import '../../styles/gifts/sass/basics';
@value gift-item from '../GiftItem/index.module.scss';

.related-gifts {
  max-width: 1440px;
  @include bb-standalone-spacing-new();

  &__title {
    font-size: 24px;
    line-height: $line-height-base;
    padding-bottom: 8px;
    color: $green-800;
    font-weight: $font-weight-bold;
    margin-bottom: 20px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    column-gap: 24px;
    row-gap: 64px;
  }

  &-bestsellers {
    max-width: none !important;
    margin: 80px 0;
    padding: 0;

    .related-gifts__title {
      margin-bottom: 20px;
      color: $gray-1000;
      font-size: 20px;
      font-weight: bold;
    }
  }
  @include media-breakpoint-up(sm) {
    &-bestsellers .related-gifts__title {
      font-size: 30px;
    }
  }

  @include media-breakpoint-up(md) {
    .gift-item {
      width: calc(33.33% - 24px);
    }
  }

  @include media-breakpoint-up(lg) {
    &__title {
      font-size: 30px;
      line-height: 1.4;
      padding-top: 24px;
    }
  }

  @media (min-width: $screen-xlg) {
    &__title {
      font-size: 36px;
    }
  }
}