@import '../../../styles/gifts/sass/basics';

.category-filter {
  display: table;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  &__item {
    &--highlighted {
      @include highlighed-category-links();
    }
  }

  &__button {
    display: inline-block;
    font-size: $nav-filters-font-size;
    font-weight: $font-weight-bold;
    color: $green-800;
    background-color: transparent;
    border: none;
    padding: 0;
    border-bottom: solid $green-800 3px;
    padding-bottom: 4px;
    text-decoration: none;
    text-transform: uppercase;
    @media (min-width: $screen-lg) {
      display: none;
    }
    &--collapsed {
      border-bottom-color: transparent;
      .category-filter__icon {
        color: $green-800;
        transform: rotate(90deg);
      }
    }
    &:active,
    &:hover,
    &:visited,
    &:focus {
      color: $green-800;
      outline: none;
    }
    .category-filter__icon {
      color: $biscuit-1000;
      font-size: 24px;
      transition: all 0.1s linear;
    }
  }

  &__categories-mobile {
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__wrapper {
    overflow: hidden;
    text-wrap: nowrap;
    max-width: 895px !important;

    @media all and (min-width: 1300px) {
      max-width: 970px !important;
    }

    @media all and (min-width: 1400px) {
      max-width: 1070px !important;
    }
  }

  :global {
    .nav-filters {
      max-height: 48px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%!important;

      li {
        margin-top: 5px;
        margin-right: 32px;
      }

      a {
        font-size: 18px;
        text-transform: none;
        padding-bottom: 10px;
        border-bottom-width: 5px;

        &:hover,
        &:focus {
          color: $gray-1200;
          opacity: 60%;
          outline: none;
        }
      }

      & > li.active > a {
        border-bottom-color: $green-800;
        border-bottom-width: 5px;
        color: $slate-1200;
        opacity: 100%;
        font-weight: 700;
      }
    }

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }

  @media #{$tablet-land} {
    :global {
      .nav-filters > li {
        margin-left: 11px;
        margin-right: 11px;
      }
    }
  }
}
