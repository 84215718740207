@import '../../styles/gifts/sass/basics';

.select {
  color: $slate-1200;
  position: relative;

  &__current {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      font-weight: 400;
    }

    a {
      color: $slate-1200;
      text-decoration: none;

      &:focus,
      &:hover {
        color: $slate-1200;
        opacity: 60%;
      }
    }

    &-icon {
      color: $slate-1200;
      font-size: 30px;
      font-weight: 400;
      margin-left: 4px;
      transform: rotate(90deg);
      transition: 0.3s all;

      &--expanded {
        transform: rotate(270deg);
      }
    }
  }

  &__option {
    cursor: pointer;
    font-size: 14px;

    &--highlighted {
      font-weight: 700;

      @include highlighed-category-colors();
    }

    a {
      color: $slate-1200;
      display: inline-block;
      text-decoration: none;
      padding: 8px 12px;
      width: 100%;

      &:focus,
      &:hover {
        color: $slate-1200;
        opacity: 60%;
      }
    }

    &--current,
    &--current a {
      background: $slate-100!important;
      font-weight: 700;
    }

    &:hover,
    &:hover a {
      background: $gray-50;
    }
  }

  &__options {
    background: $white;
    padding: 8px 0;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.20);
    border-radius: 4px;
    position: absolute;
    z-index: 10;
    width: 144px;
  }
}
